






import Vue from 'vue'
import Component from 'vue-class-component'
import {mapState} from "vuex";
import {FundManager} from "@/modules/genprox/models/User";

@Component({
  computed: mapState('genprox', {
    activeUserContextId: (state: any) => state.activeUserData.context?.id
  }),
})
export default class AIFMModule extends Vue {

  activeUserContextId!: string;

  async mounted(){
    this.$store.dispatch('genprox/getManagementTeam', this.activeUserContextId);
    this.$store.dispatch('regReporting/getFunds');
    const fundData: FundManager = await this.$store.dispatch('genprox/getContextData');
    this.$store.commit('regReporting/setFundData', fundData);
  }

}
